#logo{ margin-top: 8px; }
.form__upload,
.form__login {
  text-align: center;
  display:flex;
  height: 90vh;
  justify-content: center;
}

.form__upload div.ui.segment ,
.form__login div.ui.segment {
  margin: auto;
}

.dropzone {
  padding: .6em;
  background: lightgray;
  border-radius: 5px;
  border: medium dashed grey;
  text-align: center;
  width: 100%;
}
  
.dropzone .label{
  width: 100%;
  margin: 0 auto;
}

.login {
  height: 100vh;
  width: 100vw;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden;
  background: url('images/dachs.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.login>.column {
  width: auto !important;
  padding: 30px;
  background: rgba(0,0,0,0.7);
  border-radius: 10px;
}

.login>.column>* {
  margin: 30px 20px !important;
}

.full_width {
  width: 100vw;
}

.full_height {
  height: 100vh;
}

.ui.header .sub.header.inverted {
  color: rgba(255,255,255,.6);
}